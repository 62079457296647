import React from "react";
import EXP from '../assets/prices/tages.jpeg';
import BET from '../assets/prices/beautymakup.jpeg';
import MAR from '../assets/prices/marriage.jpeg';
import KIT from '../assets/prices/kinderschminken.jpeg';

const Prices = () => {
  const techs = [
    {
      id: 1,
      src: EXP,
      child: (<><tr>
        <td className="text-left p-2">Tages Makeup</td>
        <td className="text-right">90 CHF</td>
        </tr>
        <tr>
        <td className="text-left p-2">Abend Makeup</td>
        <td className="text-right">120 CHF</td>
        </tr>
        <tr>
        <td className="text-left p-2">Laufsteg & Show </td>
        <td className="text-right">auf Anfrage</td>
        </tr></>),
    },
    {
        id: 2,
        src: BET,
        child: (<><tr>
          <td className="text-left p-2">Kreatives Fotoshooting</td>
          <td className="text-right">auf Anfrage</td>
          </tr>
          <tr>
          <td className="text-left p-2">Fotoshooting</td>
          <td className="text-right">auf Anfrage</td>
          </tr>
          <tr>
          <td className="capitalize text-left p-2">MakeUp Tutorial inkl. StarterSet</td>
          <td className="text-right">250 CHF pro Person</td>
          </tr>
          </>),
      },
      {
        id: 3,
        src: MAR,
        child: (<><tr>
          <td className="text-left p-2">Probe MakeUp vor Hochzeit</td>
          <td className="text-right">150 CHF</td>
          </tr>
          <tr>
          <td className="text-left p-2">Braut Makeup am Hochzeitstag</td>
          <td className="text-right">250 CHF</td>
          </tr>          <tr>
          <td className="text-left p-2">Probe & Hochzeit</td>
          <td className="text-right">400 CHF</td>
          </tr>          <tr>
          <td className="text-left p-2">Aufrischung MakeUp während dem Fest</td>
          <td className="text-right">120 CHF</td>
          </tr>
          <tr>
          <td className="text-left p-2">Buchung Für die ganze Feier</td>
          <td className="text-right">auf Anfrage</td>
          </tr>
          </>),
      },
      {
        id: 4,
        src: KIT,
        child: (<>
          <tr>
          <td className="capitalize text-left p-2">Kinderschminken</td>
          <td className="text-right">auf Anfrage</td>
          </tr>
          </>),
      },
  ];

  return (
    <div
      name="prices"
      className="bg-gradient-to-b to-gray-800 from-black via-black w-full h-full"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
            Preisliste
          </p>
        </div>

        <div className="w-full py-8 px-12 sm:px-0">
          {techs.map(({ id, src, child }) => (
            <div
              key={id}
              className="columns-2 pt-2 shadow-md hover:scale-105 duration-500 py-1 rounded-lg shadow-gray-800"
            >
              <img src={src} alt="" className="flex item-left justify-left w-80 mx-auto" />
              <table className="table-fixed mt-2 pt-10 mx-auto w-full">
                <tbody>       
                {child}
                </tbody>
              </table>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prices;