import React from 'react'
import {FaFacebook, FaInstagramSquare} from 'react-icons/fa'

const SocialLinks = () => {

    const links = [
        {
            id: 1,
            child: (
                <>
                    Facebook <FaFacebook size={30} />
                </>
            ),
            href: 'https://www.facebook.com/rhea.a.821makeupartist/',
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                    Instagram <FaInstagramSquare size={30} />
                </>
            ),
            href: 'https://www.instagram.com/rhea_a_makeupartist/',
            style: 'rounded-br-md'

        },
    ]
  return (
    <div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
            {links.map(({id, child, href, style}) => (
                <li 
                key={id}
                className={'flex justify-between items-center w-40 h-14 px-4 bg-gray-500 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 ' + style}
                >
                    <a 
                        href={href}
                        className='flex justify-between item-center w-full text-white'
                        target='_blank'
                        rel="noreferrer"
                    >
                        {child}
                    </a>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default SocialLinks