import React from 'react'

const Footer = () => {
  return (
    <div className=' bg-gradient-to-b via-black from-black to-gray-800 text-white pt-2'>
        <p>Ⓒ Rhea Antonucci</p>
    </div>
  )
}

export default Footer