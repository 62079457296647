import React from "react";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-gradient-to-b via-black to-black from-gray-800 text-white p-4"
    >
      <div className="flex flex-col justify-center max-w-screen-lg mx-auto h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            GET IN TOUCH
          </p>
          <p className="py-6">Ich freue mich von dir zu hören und über unser nächstes gemeinsame Projekt zu sprechen.</p>
        </div>

        <div className=" flex justify-center items-center">
          <div className="pr-20 mb-40 leading-9">
            <h3>ADRESSE</h3>
            <p>Längistrasse 18</p>
            <h4>TELEFON</h4>
            <p>0041 76 504 49 29</p>
            <h4>EMAIL</h4>
            <p>rhea@antonucci.ch</p>
          </div>
          <form
            action="https://getform.io/f/09c12ccd-888d-4cee-9880-e38a8bef933c"
            method="POST"
            className=" flex flex-col w-full md:w-1/2"
          >
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              rows="10"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            ></textarea>

            <button className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;