import * as React from "react";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import photos from "../assets/portfolio/images"
import slides from "../assets/portfolio/slides";

const Portfolio = () => {
  const [index, setIndex] = React.useState(-1);

  return (
    <div 
        name="portfolio"
        className='w-full bg-gradient-to-b from-gray-800 via-black to-black'
    >
        <div className="max-w-screen-lg p-4 mx-auto">

            <PhotoAlbum
                layout="columns"
                photos={photos}
                targetRowHeight={150}
                onClick={(event, photo, index) => setIndex(index)}
                componentsProps={{ imageProps: { loading: "lazy" } }}
            />

            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
            />
      </div>
    </div>
  );
};

export default Portfolio;