const images = [
    {
        key:0,
        src: require('./image01.jpeg'),
        width:622,
        height:933
    },
    {
        key: 1,
        src: require('./image02.jpeg'),
        width: 960,
        height: 960
    },
    {
        key: 2,
        src: require('./image03.jpeg'),
        width:1200,
        height:1800
    },
    {
        key: 3,
        src: require('./image04.jpeg'),
        width:1500,
        height:1875
    },
    {
        key: 4,
        src: require('./image05.jpeg'),
        width:965,
        height:965
    },
    {
        key: 5,
        src: require('./image06.jpeg'),
        width:952,
        height:965
    },
    {
        key: 6,
        src: require('./image07.jpeg'),
        width:965,
        height:965
    },
    {
        key: 7,
        src: require('./image08.jpeg'),
        width:965,
        height:965
    },
    {
        key: 8,
        src: require('./image09.jpeg'),
        width:965,
        height:965
    },
    {
        key: 9,
        src: require('./image10.jpeg'),
        width:965,
        height:965
    },
    {
        key: 10,
        src: require('./image11.jpeg'),
        width:965,
        height:965
    },
    {
        key: 11,
        src: require('./image12.jpeg'),
        width:965,
        height:965
    },
    {
        key: 12,
        src: require('./image13.jpeg'),
        width:965,
        height:965
    },
    {
        key: 13,
        src: require('./image14.jpeg'),
        width:965,
        height:965
    },
    {
        key: 14,
        src: require('./image15.jpeg'),
        width:965,
        height:965
    },
    {
        key: 15,
        src: require('./image16.jpeg'),
        width:965,
        height:965
    },
    {
        key: 16,
        src: require('./image17.jpeg'),
        width:965,
        height:965
    },
    {
        key: 17,
        src: require('./image18.jpeg'),
        width:965,
        height:965
    },
    {
        key: 18,
        src: require('./image19.jpeg'),
        width:965,
        height:965
    },
    {
        key: 19,
        src: require('./image20.jpeg'),
        width:965,
        height:965
    },
    {
        key: 20,
        src: require('./image21.jpeg'),
        width:965,
        height:965
    },
    {
        key: 21,
        src: require('./image22.jpeg'),
        width:965,
        height:965
    },
    {
        key: 22,
        src: require('./image23.jpeg'),
        width:965,
        height:965
    },
    {
        key: 23,
        src: require('./image24.jpeg'),
        width:965,
        height:965
    },
    {
        key: 24,
        src: require('./image25.jpeg'),
        width:965,
        height:965
    },
    {
        key: 25,
        src: require('./image26.jpeg'),
        width:2560,
        height:1576
    },
    {
        key: 26,
        src: require('./image27.jpeg'),
        width:1125,
        height:1500
    },
    {
        key: 27,
        src: require('./image28.jpeg'),
        width:426,
        height:640
    },
    {
        key: 28,
        src: require('./image29.jpeg'),
        width:1778,
        height:1396
    },
    {
        key: 29,
        src: require('./image30.jpeg'),
        width:510,
        height:768
    },
    {
        key: 30,
        src: require('./image31.jpeg'),
        width:427,
        height:640
    },
    {
        key: 31,
        src: require('./image32.jpeg'),
        width:640,
        height:427
    },
    {
        key: 32,
        src: require('./image33.jpeg'),
        width:507,
        height:640
    },
    {
        key: 33,
        src: require('./image34.jpeg'),
        width:640,
        height:427
    },
    {
        key: 34,
        src: require('./image35.jpeg'),
        width:720,
        height:960
    },
    {
        key: 35,
        src: require('./image36.jpeg'),
        width:960,
        height:637
    },
    {
        key: 36,
        src: require('./image37.jpeg'),
        width:640,
        height:420
    },
    {
        key: 37,
        src: require('./image38.jpeg'),
        width:637,
        height:960
    },
];

export default images;