import React from 'react';
import ME from '../assets/me_rhea.jpeg';


const Home = () => {
  return (
    <div 
        name="home" 
        className='h-screen w-full bg-gradient-to-b from-black via-black to-gray-800'
    >
        <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
            <div className='flex flex-col justify-center h-full'>
                <p className='text-gray-500 py-4 max-w-md'>
                As a MakeUp Artist, I have the privilege of working with many different and unique people. 
                Between Bridal, editorial and special occasion makeup, 
                I am able to create different looks especially request 
                by my clients.
                </p>  
                <p className='text-gray-500 py-4 max-w-md'>
                Being a Professional Makeup Artist I have an eye for 
                which look fits best for each my clients. I want them
                to be happy and be comfortable on their look once 
                they leave my makeup chair.
                </p>
            </div>
            <div className='w-1/4 mx-auto'>
                <img 
                src={ME} 
                alt="me" 
                className='rounded-2xl' 
                />
            </div>
        </div>
    </div>
  )
}

export default Home